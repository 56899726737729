import Service from "../Service";
import Vue from "vue";
//Services
const resource = "Detraction/"
export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
}