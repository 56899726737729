<template>
  <div>
    <s-toolbar label="Actualizacion de Archivo Facturas de Detracciones" />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col lg="12">
            <v-label> Carga Masiva de Actualizacion de Detracciones </v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s-col-form" sm="5" md="5">
            <s-import-excel
              label="Leer archivo excel"
              @clear="itemsDetraction = []"
              @changeData="changeFile($event)"
            ></s-import-excel>
          </v-col>
          <v-col cols="5" md="5" class="s-col-form">
            <s-load
              @clear="clear()"
              label="Adjunte archivo txt"
              v-model="files"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s-col-form" sm="12" md="12">
            <v-data-table
              dense
              :single-select="true"
              :headers="headers"
              @click:row="rowClick"
              :items="itemsDetraction"
              locale="es-ES"
              selectable-key="isSelectable"
              :items-per-page="10"
              item-key="ID"
              ref="stable"
              v-model="selected"
              :footer-props="{
                showCurrentPage: true,
                showPageText: true,
                showFirstLastPage: false,
                showPerPageOptions: true,
                showPerPageText: true,
                itemsPerPageAllText: 'Todos',
                noResultsText: 'sin resultados',
                noDataText: 'sin resultados',
                itemsPerPageOptions: [10, 25, 50, -1],
                itemsPerPageText: 'Registros',
              }"
            >
              <template slot:item.WacStatus="{ item }"> </template>
              <template slot="no-data"> Sin Datos </template>
              <template slot="no-results"> Sin Datos </template>
              <template slot:footer.page-text="{ pageStop, itemsLength }">
                {{ itemsLength }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-col class="s-col-form" @click="cancel()"
          ><v-btn block text>Cancelar</v-btn></v-col
        >
        <v-col class="s-col-form"
          ><v-btn block color="primary" @click="save()">
            <v-icon small left>fas fa-save</v-icon>Guardar</v-btn
          ></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _Detraction from "@/services/Management/DetractionService";

import _sHelper from "@/services/HelperService";

export default {
  components: {},
  props: {
    FechaVencimiento: { type: Date },
  },
  data: () => ({
    itemsDetraction: [],
    selected: [],
    files: [],
    config: {
      model: {
        FechaVencimiento: "date",
        FechaDocumento: "date",
      },
    },
    headers: [
      {
        text: "F.Vencimiento",
        value: "FechaVencimiento",
        column: "FechaVencimiento",
        date: true,
      },
      {
        text: "F.Documento",
        value: "FechaDocumento",
        column: "FechaDocumento",
      },
      { text: "Moneda", value: "Moneda", width: 80 },
      { text: "Total", value: "Total" },
      { text: "Total Detra S/.", value: "TotalDetra" },
      { text: "Nro. Sunat", value: "NroSunat", width: 140 },
      { text: "Descripción", value: "DescDetra", column: "DescDetra" },
      { text: "Tipo", value: "TipoF" },
      { text: "DocNum", value: "DocNum", column: "NroSap" },
      { text: "Archivo", value: "OptFile", column: "NameFile" },
      { text: "Mensaje", value: "OptMessage" },
    ],
    itemsLength: 0,
    OptFile: "",
  }),
  watch: {
    files() {
      if (this.files) this.changeData(this.files);
    },
  },
  methods: {
    rowClick: function (item, row) {
      this.selected = [item];
      this.row = Object.assign({}, item);
      this.$emit("rowSelected", this.selected);
    },
    changeFile(data) {
      let item = {};
      var i = 0;
      data.forEach((element) => {
        item = {
          FechaVencimiento: this.$fun.getDate(element.FechaVencimiento),
          FechaDocumento: this.$fun.getDate(element.FechaDocumento),
          TotalDetra: data[i]["Total Detra S/."],
          Moneda: element.Moneda,
          Total: element.Total,
          NroSunat: data[i]["Nro Sunat"],
          DescDetra: data[i]["Desc. Detra"],
          TipoF: data[i]["Tipo F."],
          DocNum: data[i]["Nro Sap"],
          OptFile: "",
        };
        i++;
        item.ID = this.itemsDetraction.length + 1;
        this.itemsDetraction.push(item);
      });
    },
    save() {
      if (this.itemsDetraction.length > 0) {
        if (this.files != null) {
          this.$fun.alert("Seguro de Guardar", "question").then((r) => {
            if (r.value) {
              var fileName = this.files.name;
              this.itemsDetraction.map((x) => {
                x.OptFile = fileName;
                x.TypeOperation = 18;
                x.IsOperation = 1;
                return x;
              });
              var formData = new FormData();
              formData.append("file", this.files);

              _sHelper
                .uploadfile(1, this.$fun.getUserID(), formData, 3)
                .then((respose) => {
                  if (respose.status == 200) {
                    _Detraction
                      .save(this.itemsDetraction, this.$fun.getUserID())
                      .then((resp) => {
                        if (resp.status == 200) {
                          var clon = this.itemsDetraction;
                          var register = this.itemsDetraction.length;

                          var add = [];

                          _.each(resp.data, function (objeto) {
                            clon = clon.filter(
                              (x) => objeto.DocNum == x.DocNum
                            );

                            let item = {
                              DocNum: objeto.DocNum,
                              OptMessage: objeto.OptMessage,
                            };
                            add.push(item);
                          });
                          this.itemsDetraction = [];
                          this.itemsDetraction = add;
                          if (register - add.length > 0) {
                            this.$fun.alert(
                              "Agregados Correctamente " +
                                (register - resp.data.length),
                              "success"
                            );
                          } else
                            this.$fun.alert(
                              "Verifique mensaje de los registros ",
                              "warning"
                            );
                        }
                      });
                  }
                });
            }
          });
        } else {
          this.$fun.alert("Anexe Archivo de Detracciones", "warning");
        }
      } else {
        this.$fun.alert("Sin datos que cargar", "warning");
      }
    },
    changeData(items) {
      if (this.txtFile(items.name) == "txt") {
        return true;
      } else {
        this.files = [];
        this.$fun.alert("Formato No permitido", "error");
        return false;
      }
    },

    txtFile(file) {
      return file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    clear() {},
    cancel() {
      this.itemsDetraction = [];
    },
  },
};
</script>
